import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Hero from "../../components/hero/hero"
import RightCta from "../../components/cta/right-bordered/cta"
import LeftCta from "../../components/cta/left-bordered/cta"
import Savings from "../../components/savings/savings";


import desktopHero from "../../images/talking-with-your-doctor/1.2_1440x540_Desktop_Hero_1X.png"
import mobileHero from "../../images/talking-with-your-doctor/1.2_543x800_Mobile_Hero_2X.png"
import clipboard from "../../images/talking-with-your-doctor/1.2_Clipboard_Icon.png"
import gradientOne from "../../images/talking-with-your-doctor/gradientOne.png"
import gradientTwo from "../../images/talking-with-your-doctor/gradientTwo.svg"
import ctaBackground from "../../images/talking-with-your-doctor/ctaBackground.svg"
import laptop from "../../images/protect-against-another-heart-attack/laptop_q.png"
import vascepaPill from "../../images/talking-with-your-doctor/1.2_550x402_Pill_Desktop_1X.png"
import vascepaPillMobile from "../../images/talking-with-your-doctor/1.2_543x300_Pill_Mobile_2X.png"


export default () => (
  <Layout>
    <SEO
      title="Use Our Doctor Discussion Guide"
      keywords="VASCEPA doctor discussion guide"
      description="Additional protection for your heart starts by talking to your doctor. Use our doctor discussion guide for a resource on what to ask at your next doctor's visit. Please see Indications and Important Safety Information."
       />

      <Hero
        desktopHeroBackground={desktopHero}
        mobileHeroBackground={mobileHero}
        alt="A man and his doctor, making a plan to protect his heart with VASCEPA® (icosapent ethyl)"
        headline={"Make a plan to protect your heart"}
        content={["Protect your health. Protect your heart.",<br/>,<br/>,"Ask your doctor about additional protection for your heart from VASCEPA."]}
        additionalClassName = "height640"
        />

      {<RightCta
        colOne="5"
        colTwo="7"
        whiteborder="whiteborder"
        image={clipboard}
        mobileImage={clipboard}
        alt="Clipboard icon"
        background={gradientOne}
        backgroundGradient="#01add6"
        headline="Using what you learned from the quiz, download and complete this guide for your next conversation with your doctor"
        cta={["Download discussion guide ", <span aria-hidden="true">&gt;</span>]}
        url="https://amarincorp.com/docs/Vascepa-Downloadable-DDG-2020.pdf"
        external="true"
        />
        }

      <LeftCta
          ctaBackground = {ctaBackground}
          colOne="5"
          colTwo="7"
          whiteborder="transparentborder"
          image={laptop}
          mobileImage={laptop}
          alt="Laptop icon"
          backgroundColor = "#E7E8EA"
          headline="Still haven’t taken the quiz?"
          cta={["Take the quiz ",  <span aria-hidden="true">&gt;</span>]}
          headlineColor = "#1D355E"
          ctaColor = "#1D355E"
          url="/cardiovascular-risk/quiz"
          contentSubClass="left"
          ctaSubClass="left"
          />



<div className="block row">
      <div
        style={{ padding: "0px",}}
        className="col-md-5 block-content whiteborderRight center-img mobile-height-200"
      >
			<img className="mobile-hide" src={vascepaPill} alt="Vascepa capsule"></img>
			<img className="d-lg-none img-fullwidth img-fullheight" src={vascepaPill} alt="Vascepa capsule"></img>
      </div>

      <div className="col-md-7 block-content resource-block">
        <img class="background-gradient blink-section" style={{width:"auto"}} src={gradientTwo} alt=""></img>
        <div class="content mobile-p-b-100">
          <h3
            className="font-margins"
            style={{
              fontSize: "30px",
              lineHeight: "42px",
              fontWeight: "700",
              zIndex: "1",
              position: "relative",
              color: "#1D355E"
            }}
          >
            Find out how VASCEPA reduces your risk of a heart attack or stroke
          </h3>
          <p
            className="font-margins"
            style={{
              fontSize: "12px",
              zIndex: "1",
              position: "relative",
              marginBottom: "0px",
              color: "#1D355E"
            }}
          >
            Capsule is not actual size.
          </p>
		  <div class="links">
			<h3>
				<Link to="/benefits" className="style-a1" style={{color: "#1D355E"}}>VASCEPA benefits &gt;</Link>
			</h3>
		  </div>
		 
        </div>
      </div>
    </div>





  </Layout>
)
